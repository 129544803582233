/* eslint-disable react/no-unescaped-entities, no-empty */

/* eslint-disable react/jsx-curly-newline, react/jsx-indent */

/* eslint-disable react/jsx-wrap-multilines, jsx-a11y/alt-text */

/* eslint-disable no-unneeded-ternary, import/no-unresolved */
import React, { useId, useState } from 'react';

import dynamic from 'next/dynamic';
import Image from 'next/image';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// eslint-disable-next-line object-curly-newline
import { A11y, Controller, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import useSourceScreen from '../../../hooks/useSourceScreen';

import { IMAGE_PREFIX } from '../../../constants/api';

import styles from '../../../styles/Home.module.scss';

const ShakaPlayerTestimonials = dynamic(() => import('./ShakaPlayerTestimonials'));
const RightSlider = dynamic(() => import('../../../assets/images/right-slider.svg'));
const ShieldDone = dynamic(() => import('../../../assets/images/Shield-Done.svg'));
const FileIcon = dynamic(() => import('../../../assets/images/FileIcon.svg'));

function SwiperTestimonials(props) {
  const { testimonials } = props;

  const sliderId = useId();

  const trackMoengageEvent = useMoengageEventTracker();
  const [swiper, setSwiper] = React.useState();
  const [disabledData, setDisabledData] = useState({
    prevFlag: true,
    nextFlag: false,
  });
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [video, setVideo] = useState('');
  const [navigationFlag, setNavigationFlag] = useState(false);
  const prevRef = React.useRef();
  const nextRef = React.useRef();
  const screen = useSourceScreen();

  React.useEffect(() => {
    prevRef.current.click();
  }, []);

  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  React.useEffect(() => {
    const prevElement = prevRef?.current;
    const nextElement = nextRef?.current;
    const disabledDataObj = {
      prevFlag: prevElement?.className?.includes('swiper-button-disabled') ? true : false,
      nextFlag: nextElement?.className?.includes('swiper-button-disabled') ? true : false,
    };
    setDisabledData(disabledDataObj);
  }, [navigationFlag]);

  const swiperButtonClick = () => {
    setNavigationFlag(!navigationFlag);
  };
  const displayVideo = (item) => {
    setIsShowVideo(true);
    setVideo(item);
    try {
      trackMoengageEvent('testimonial_video_play', {
        testimonial_id: '',
        testimonial_name: item.name,
        testimonial_video_id: '',
      });
    } catch (error) {}
  };
  const hideVideo = () => {
    setIsShowVideo(false);
    setVideo('');
  };
  const handleVideoClick = (e) => {
    e.stopPropagation();
  };

  const handleTestimonialMoe = (value) => {
    if (screen && value) {
      try {
        trackMoengageEvent('arrow_clicked', {
          arrow_type: value,
          source_screen: screen,
          screen_name: 'testimonials',
        });
      } catch (error) {}
    }
  };

  return (
    <div className="App">
      <div className="carousel-container">
        <div
          className="swiper-button prev"
          ref={prevRef}
          id="prev"
          onClick={() => {
            swiperButtonClick(prevRef, 'prev');
          }}
        >
          {!disabledData.prevFlag ? (
            <span
              className={`${styles.prevIcon} shadowOnHover sliderArrowIconGlobal`}
              onClick={() => {
                handleTestimonialMoe('left');
              }}
            >
              <RightSlider />
            </span>
          ) : (
            <Image
              src={`${IMAGE_PREFIX}/right-slider-disabled.png`}
              className={`${styles.prevIcon} sliderArrowIconGlobal`}
              alt="Prev Icon"
              loading="lazy"
              height={80}
              width={80}
            />
          )}
        </div>
        <Swiper
          id={sliderId}
          modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
          className="external-buttons"
          spaceBetween={24}
          slidesPerView={1}
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current,
          }}
          updateOnWindowResize
          observer
          observeParents
          initialSlide={0}
          onSlideChange={() => setNavigationFlag(!navigationFlag)}
          onSwiper={setSwiper}
        >
          {testimonials &&
            testimonials.length > 0 &&
            testimonials.map((elem, index) => (
              <SwiperSlide
                index={index}
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}testimonials1`}
              >
                <div className={styles.swiperSlideEach}>
                  {elem?.profileImg ? (
                    <Image
                      className={styles.profilePic}
                      src={elem.profileImg}
                      alt={elem.name}
                      height={310}
                      width={310}
                    />
                  ) : (
                    <div className={styles.image_placeholder}>
                      <h4>NO IMAGE</h4>
                    </div>
                  )}
                  <div className={styles.icon1}>
                    <FileIcon />
                  </div>
                  <div className={styles.icon2}>
                    <ShieldDone />
                  </div>
                  <div className={styles.info}>
                    {(elem.vimeourl || elem.s3Url) && (
                      <Image
                        className={styles.play}
                        src={`${IMAGE_PREFIX}/testimonial-play_P.png`}
                        alt="Play Video"
                        height={20}
                        width={20}
                        onClick={() => displayVideo(elem)}
                      />
                    )}
                    <span className={styles.infoLine} />
                    <p className={`${styles.p1} ${styles.infoMargin}`}>{elem.name}</p>
                    <p className={`${styles.p2} ${styles.infoMargin}`}>"{elem.title}"</p>
                    <div className={`${styles.rating} ${styles.infoMargin}`}>
                      {[...Array(elem.rating)].map((e, i) => (
                        <Image
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          src={`${IMAGE_PREFIX}/star_P.png`}
                          alt="rating star"
                          height={11}
                          width={11}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        <div
          className="swiper-button next"
          ref={nextRef}
          id="next"
          onClick={() => {
            swiperButtonClick(nextRef, 'next');
          }}
        >
          {!disabledData.nextFlag ? (
            <span
              className={`${styles.nextIcon} shadowOnHover sliderArrowIconGlobal`}
              onClick={() => handleTestimonialMoe('right')}
            >
              <RightSlider />
            </span>
          ) : (
            <Image
              src={`${IMAGE_PREFIX}/right-slider-disabled.png`}
              className={`${styles.nextIcon} sliderArrowIconGlobal`}
              alt="Next Icon"
              loading="lazy"
              height={80}
              width={80}
            />
          )}
        </div>
      </div>

      {isShowVideo && (
        <div className={styles.h_t_v_wrapper} onClick={hideVideo}>
          <div className={styles.h_t_v_player} onClick={handleVideoClick}>
            {video?.s3Enabled === 1 && <ShakaPlayerTestimonials videoUrl={video?.s3Url} />}
          </div>
        </div>
      )}
    </div>
  );
}
export default SwiperTestimonials;
